header {
  /* background-image: url(../../public/assets/IMG_2062.webp); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* height: 100vh; */
  /* max-height: 100vh; */
  position: relative;
}

.topnav ul {
  list-style: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.topnav li a {
  color: #DBE8D4;
  text-align: center;
  margin: 5px 10px;
  display: inline-block;
  text-decoration: none;
  font-size: 25px;
}

.titleCard {
  color:#DBE8D4;
  text-align: center;
}

.headerInfo {
  padding: 20vh 0;
}

.titleCard h1 {
  font-size: 90px;
}

.titleCard h2 {
  font-size: 40px;
  margin-top: 10px;
}

.construction {
  color:#DBE8D4;
  text-align: center;
  padding-top: 30vh;
  font-size: 25px;
  font-family: 'Roboto', serif;
  font-weight: 300;
}

.resume {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resumeClick {
  font-family: 'Vidaloka', serif;
  color: rgb(12, 29, 36);
  background-color: rgba(219, 232, 212, 0.9);
  padding: 10px;
  width: 200px;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid rgb(12, 29, 36);
}

.resumeClick:hover {
  background-color: rgb(12, 29, 36);
  color: rgba(219, 232, 212, 0.9);
  border: 2px solid rgb(12, 29, 36);
  cursor: pointer;
}

.headNav {
  background-color: #2E3445;
  color: #DBE8D4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.headNav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headNav p {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #DBE8D4;
}

.headNav li a {
  text-align: center;
  padding: 20px;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  color: #DBE8D4;
}