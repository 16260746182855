.education {
  margin-bottom: 150px;
}

.education h3 {
  margin: 25px 0px;
  padding: 0px 0px 25px 0px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  color: #DBE8D4;
  border-bottom: 1px solid #DBE8D4;
}

.card {
  background-color: #2E3445;
  border-radius: 20px;
  color: #DBE8D4;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
  width: 100%;
  font-size: 1.2rem;
  transition: all .2s ease-in-out;
  border: 1px solid #2E3445;
}

.card:hover {
  transform: scale(1.2);
  border: 1px solid #DBE8D4;
}

.aboutCards {
  display: flex; 
  flex: 1 1 auto;
}

.card h5 {
  margin: 5px;
  font-size: 1.4rem;
}

.card h6 {
  margin: 5px;
  font-size: 1.3rem;
  font-weight: normal;
}

.card p {
  margin: 5px 10px 30px 10px;
}

.card img {
  margin: 30px 0px;
  width: 400px;
  height: 200px; 
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .aboutCards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .card img {
    width: 250px;
    height: 125px;
  }
}

@media (max-width: 370px) {
  .card img {
    width: 200px;
    height: 100px;
  }
}