.footer {
  margin-top: 100px;
  background-color: #2E3445;
  color: #DBE8D4;
  min-height: 200px;
}

.footnav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footnav p {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #DBE8D4;
}

.footnav li a {
  text-align: center;
  padding: 20px;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  color: #DBE8D4;
  margin-top: 25px;
}

@media (max-width: 650px) {
  .footnav li a {
    padding: 10px;
  }
}