.skills h3 {
  margin: 25px 0px 50px 0px;
  padding: 0px 0px 25px 0px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  color: #DBE8D4;
  border-bottom: 1px solid #DBE8D4;
}

.skillContainer {
  color: #DBE8D4;
  display: flex;
  border: 1px solid #DBE8D4;
  margin: 20px 0px 150px 0px;
}

.skillContainer h5 {
  font-size: 1.2rem;
  padding: 15px 0px;
  margin: 0px;
  height: calc(100% / 5);
}

.skillContainer h5:hover {
  background-color: #DBE8D4;
  cursor: pointer;
  color: #222733;
}

skill_type {
  background-color: transparent;
}

.skill-type-selected {
  background-color: #DBE8D4;
  color: #222733
}

.skillSidebar {
  width: 30%;
  text-align: center;
  border-right: 1px solid #DBE8D4;
}

.skillContainer article {
  width: 80%;
  margin: 10px 20px;
}

.skillContainer ul {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 15rem;
  list-style: square;
}

.skillContainer li {
  font-size: 1.3rem;
  margin: 5px 5px 5px 25px;
}

@media (max-width: 600px) {
  .skillContainer ul{
    max-height: 25rem;
  } 
  .skillSidebar {
    width: 35%;
  }
}

@media (max-width: 370px) {
  .skillContainer ul{
    max-height: 30rem;
  } 

  .skillSidebar {
    width: 30%;
  }

  .skillContainer article {
    width: 70%;
  }
}