.contact h3 {
  margin: 25px 0px 40px 0px;
  padding: 0px 0px 25px 0px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  color: #DBE8D4;
  border-bottom: 1px solid #DBE8D4;
}

.contactLinks {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contactLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  color: #DBE8D4;
  cursor: pointer;
  width: 200px;
}

.contactLink i {
  color: #2E3445;
  background-color: #DBE8D4;
  font-size: 3rem;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.contactLink h5 {
  margin: 20px 0px 0px 0px;
  font-size: 1.3rem;
}

.contactLink a {
  margin: 0;
}

.contactLink h6 {
  margin: 5px 0px 5px 0px;
  font-size: 1.2rem;
  font-weight: normal;
}

.sendMessage {
  margin: 0px 0px 5px 0px;
  font-size: 1.5rem;
  font-weight: normal;
  color: #DBE8D4;
  text-align: center;
}

.contactLink:hover {
  i {
    background-color: #2E3445;
    color: #DBE8D4;
  }
}

@media (max-width: 1200px) {
  .contactLink {
    margin: 20px 5px;
  }
}

@media (max-width: 1050px) {
  .contactLink {
    width: 40%;
  }
}

@media (max-width: 440px) {
  .contactLink h6 {
    display: none;
  }
}