.projects h3 {
  margin: 25px 0px;
  padding: 0px 0px 25px 0px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  color: #DBE8D4;
  border-bottom: 1px solid #DBE8D4;
}

.item {
  background-color: #2E3445;
  border-radius: 20px;
  color: #DBE8D4;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
  width: 100%;
  font-size: 1.2rem;
  transition: all .2s ease-in-out;
  border: 1px solid #2E3445;
}

.item:hover {
  transform: scale(1.2);
  border: 1px solid #DBE8D4;
}

.projects img {
  height: 192px;
  width: 341px;
  margin: 20px 10px 10px 10px;
}

.item h5 {
  margin: 5px;
  font-size: 1.4rem;
}

.item h6 {
  margin: 5px 30px 5px 30px;
  font-size: 1.3rem;
  font-weight: normal;
}

.item p {
  margin: 5px 10px 30px 10px;
}

.gitButton {
  display: flex;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #DBE8D4;
  background-color: #DBE8D4;
  color: #2E3445;
  cursor: pointer;
}

.gitButton p {
  text-align: center;
  margin: 0px 0px 0px 10px;
}

.gitButton:hover {
  background-color: #2E3445;
  color: #DBE8D4;
}

.carousel-wrapper{
  height:550px;
  position:relative;
  width: 100%;
  margin:0 auto;
  margin-bottom: 150px;
}

.carousel-item{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  padding:25px 50px;
  opacity:0;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: space-evenly;
}

.arrow{
  border: solid #DBE8D4;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 12px;
}

.arrow-prev{
  left:-30px;
  position:absolute;
  top:50%;
  transform:translateY(-50%) rotate(135deg);
}

.arrow-next{
    right:-30px;
  position:absolute;
  top:50%;
  transform:translateY(-50%) rotate(-45deg);
  }

[id^="item"] {
    display: none;
  }

.item-1 {
    z-index: 2;
    opacity: 1;
  }

*:target ~ .item-1 {
    opacity: 0;
  }

#item-1:target ~ .item-1 {
    opacity: 1;
  }

#item-2:target ~ .item-2, #item-3:target ~ .item-3 {
    z-index: 3;
    opacity: 1;
  }


@media screen and (max-width: 800px) {
  .carousel-item {
    display: flex;
    flex-direction: column;
  }

  .carousel-wrapper {
    height: 1000px;
  }
}

@media screen and (max-width: 560px) {
  .projects img {
    height: 153px;
    width: 272px;
  }

  .item {
    margin: 5px 10px;
  }
}

@media screen and (max-width: 480px) {
  .projects img {
      height: 100px;
      width: 200px;
  }

  .item h6 {
    margin: 5px 5px;
  }
}

@media screen and (max-width: 370px) {
  .carousel-item {
    padding: 5px;
  }

  .carousel-wrapper {
    height: 900px;
  }
}