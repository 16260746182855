.linkButtons {
  background-color: rgba(0,0,0,0.2);
  border-radius: 10px;
}

.linkContainer {
  z-index: 11;
}

.arrowButton {
  position: fixed;
  top: 70px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(0,0,0,0.2);
  border-radius: 10px;
  animation: slidein 250ms ease-in-out;
}

.click {
  color: #EBEFF1;
  text-align: center;
  margin: 5px 10px;
  display: inline-block;
  text-decoration: none;
  font-size: 25px;
}

.slide-out{
  position:fixed;
  right: 10px;
  top: 70px;
  animation: slideout 250ms ease-in-out;
}

.clickOff {
  cursor: pointer;
}

@keyframes slideout {
  0% { right: -50px; }
  100% { right: 10px; }
}

@keyframes slidein {
  0% { right: -50px; }
  100% { right: 10px; }
}

@media screen and (max-width: 500px) {
  .linkContainer {
    display: none;
  }
}