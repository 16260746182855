.aboutFlex {
  display: flex;
  flex: 1 1 auto;
}

.profilepic {
  width: 40%;
  margin: 20px 2.5% 0px 2.5%;
}

.profilepic img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.aboutDescription {
  width: 60%;
  margin: 20px 2.5% 0px 2.5%;
}

.about {
  padding: 25px 0px;
  min-height: 70vh;
}

.about h3 {
  margin: 25px 0px;
  padding: 0px 0px 25px 0px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  color: #DBE8D4;
  border-bottom: 1px solid #DBE8D4;
}

.aboutme {
  height: 100%;
  font-size: 1.4rem;
  padding: 40px;
  line-height: 2rem;
  background-color: #2E3445;
  border-radius: 20px;
  color: #DBE8D4;
}

@media (max-width: 1024px) {
  .aboutFlex {
    flex-direction: column;
    align-items: center;
  }

  .aboutDescription {
    width: 80%;
  }

  .profilepic {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .aboutFlex {
    flex-direction: column;
    align-items: center;
  }

  .aboutDescription {
    width: 100%;
  }

  .profilepic {
    width: 80%;
  }
}

@media (max-width: 370px) {
  .aboutme {
    padding: 20px 10px;
  }
}